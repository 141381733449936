import React from 'react'
import MeAndRuthie from '../Images/spfBackground.png'

const AboutSection = () => {
  return (
    <div style={{height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url('${MeAndRuthie}')`, 
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
    }}>
      <div style={{
          maxWidth: '360px',
          justifyContent: 'center',
          alignItems: 'center'
          }}>
        <h1 className='text-warning m-4'>About Us</h1>
        <p className="text-white shadow-lg bg-black m-4 p-2">
          From our first dates together RuthAnne and I have been arbitrarily rating our food together.<br/><br/>
          We found that contrary to 5 being the midpoint on a 10 point scale, if there was a restaurant that we enjoyed (but didn't
           exceed our expectations) we would rate it around 7.5. <br/><br/>
          In addition we found that the best people to leave reliable ratings for us to use would be ourselves. The only issue 
          was remembering everything we had and what we thought!<br/><br/>
          So with my CS background, and RuthAnne's data background we decided the best thing for us to would to be to create ourselves an archive.
           Thus, 7point5 was born. <br/><br/>
           However you've managed to access 7point5 whether it be through my poor security coding abilities, or given access by RuthAnne or myself we hope 
           you enjoy our reviews and enjoy taking a look at all the places we've had the pleasure of trying out.
        </p>
      </div>
    </div>
  )
}

export default AboutSection
