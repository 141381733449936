import React from 'react'
import RestaurantReview from '../components/RestaurantReview'
import NavBar from '../components/NavBar'
import {ToastContainer} from 'react-toastify';

const RestaurantDetails = () => {
  return (
    <div>
        <NavBar />
        <RestaurantReview />
        <ToastContainer />
    </div>
  )
}

export default RestaurantDetails
