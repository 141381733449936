import React, {useState, createContext} from 'react';

export const ItemsContext = createContext();

export const ItemsContextProvider = props => {
    const [items, setItems] = useState([]);

    const addItems = (item) => {
        setItems([...items, item])
    };

    return(
        <ItemsContext.Provider 
        value={{
            items, 
            setItems, 
            addItems
        }}>
            {props.children}
        </ItemsContext.Provider>
    )
};