import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavBar = () => {
    const navigate = useNavigate();

    const handleNav = () => {
        navigate(`/restaurants`)
    }
    const handleAbout = () => {
        navigate(`/about`)
    }
    const handleExit = () => {
        navigate('/')
    }
  return (
    <header>
        <nav className="fixed-top navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
            <button className="btn btn-dark" onClick={() => handleNav()}>Home</button>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="nav-overlay"></div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item my-1">
                    <button className="btn btn-dark mx-2" onClick={() => handleAbout()}>About Us</button>
                </li>
                <li className="nav-item my-1">
                    <button className="btn btn-dark mx-2" onClick={() => handleExit()}>Exit</button>
                </li>
                </ul>
            </div>
            </div>
        </nav>
    </header>
  )
}

export default NavBar
