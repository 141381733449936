import React, { useState, useContext } from 'react';
import RestaurantFinder from '../APIs/RestaurantFinder';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SignaturesContext } from '../context/SignaturesContext';

const GuestBookAdd = () => {

    const getDate = () => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${month}/${date}/${year}`;
    }
    const {addSignatures} = useContext(SignaturesContext);
    const [errors, setErrors] = useState({});
    const [guest, setGuest] = useState("");
    const [date] = useState(getDate());

    const clearState = () => {
        setGuest("")
    }

    const handleSubmitItem = async (e) => {
        e.preventDefault();
        const validationErrors = {}
        //Error catching - This should be a function but it's going to be like this for now
        if(guest.trim() === "") {
        validationErrors.guest = "Your name is required!"
        }else if (guest.length > 15) {
        validationErrors.guest = "Name must be less than 15 Characters"
        }
        setErrors(validationErrors);
        if(Object.keys(validationErrors).length === 0) {
        const spinner = toast.loading("Making Changes");
        try {
            const response = await RestaurantFinder.post(`/api/guestbook`, {
                guest: guest,
                date: date
            })
            console.log(response)
            addSignatures(response.data.data.signature);
            clearState();
            toast.update(spinner, {render: "Signature Received!",
                type: "success",
                isLoading: false,
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "dark",
        })
        }catch(err){
            console.log(err);
            toast.update(spinner, {render: "Something went wrong :(",
                type: "error",
                isLoading: false,
                position: "top-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "dark",
        })
        }
        }else{
        toast.error("Post failed, try again and review your errors!",{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
        })
        }
    }
    
    return (
        /*Button to activate Modal Open to Input Dish Info*/
        <div className='text-center my-4'>
            <button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#itemAddModal">
            Sign Guest Book
            </button>
        {/*Start of modal*/} 
            <div className="modal fade" id="itemAddModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title text-black" id="staticBackdropLabel">Thanks for visiting, leave your name here</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
        {/*Start of modal body*/}
                <div className="modal-body text-black">
        {/*Form Body start*/}
                    <form action='upload' method='POST' onSubmit={handleSubmitItem} encType='multipart/form-data'>
                    <div className="row g-3">
                        <div className="col-md-6 text-start">
                        <label htmlFor="guestName">Your Name</label>
                        <input value={guest} onChange={e => setGuest(e.target.value)} type="text" className="form-control" id="guestName" autoComplete='off'></input>
                        {errors.guest && <span style={{color:'red'}}>{errors.guest}</span>}
                        </div>
                        <div id="restaurantNameHelpBlock" className="form-text text-start">
                        Maximum 15 Characters (First Name and Last Initial Reccommended i.e. John S.)
                        </div>
                        <div className="col-md-12 text-start">
                        <label htmlFor="visitDate">Visit Date</label>
                        <input value={date} type="text" className="form-control" id="visitDate" readOnly={true} autoComplete='off'></input>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-warning" data-bs-dismiss="modal">Sign Book</button>
                    </div>
                    </form>
        {/*Form Body End*/}
                </div>
        {/*End Modal Body*/}
                </div>
            </div>
            </div>
        </div>
    );
    }
export default GuestBookAdd;
