import React from 'react'


const Pagination = ({postsPerPage, totalPosts, currentPage, paginate}) => {
    let pageNumbers =[]
    const numPages = Math.ceil(totalPosts / postsPerPage);
    
    for(let i=1; i<= numPages; i++){
    pageNumbers.push(i);
    }
    return (
        <div className="text-start" style={{minWidth:"360px"}}>
        <div>
            <ul className='pagination'>
                <li key={"newest"} className='page-item'>
                    <button className="btn btn-dark" type="button" onClick={() => {paginate(1)}}>
                        Newest
                    </button>
                </li>
                <li key={"prev"} className='page-item'>
                    <button className="btn btn-dark" type="button" onClick={() => {if(currentPage > 1 ){paginate(currentPage - 1)}}}>
                        Prev
                    </button>
                </li>
                <li key={"next"} className='page-item'>
                    <button className="btn btn-dark" type="button" onClick={() => {if(currentPage < numPages ){paginate(currentPage + 1)}}} >
                        Next
                    </button>
                </li>
                <li key={"oldest"} className='page-item'>
                    <button className="btn btn-dark" type="button" onClick={() => {paginate(numPages)}}>
                        Oldest
                    </button>
                </li>
            </ul>
        </div>
    <p className='text-light text-start'>You are on page <strong className='text-warning'>{currentPage}</strong> of <strong className='text-warning'>{numPages}</strong></p>
    </div>
    )
}

export default Pagination
