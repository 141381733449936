import React, {useState, createContext} from 'react';

export const SignaturesContext = createContext();

export const SignaturesContextProvider = props => {
    const [signatures, setSignatures] = useState([]);
    const [selectedSignature, setSelectedSignature] = useState([])

    const addSignatures = (signature) => {
        setSignatures([signature, ...signatures])
    };

    return(
        <SignaturesContext.Provider 
        value={{
            signatures, 
            setSignatures, 
            addSignatures, 
            selectedSignature, 
            setSelectedSignature
        }}>
            {props.children}
        </SignaturesContext.Provider>
    )
};