import React from 'react'
import NavBar from '../components/NavBar';
import AboutSection from '../components/AboutSection';
import {ToastContainer} from 'react-toastify';

const About = () => {
  return (
    <div>
        <NavBar />
        <AboutSection />
        <ToastContainer />
    </div>
  )
}

export default About
