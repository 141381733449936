import React from 'react'
import Login from '../components/Login'

const Landing = () => {
  return (
    <div>
        <Login />
    </div>
  )
}

export default Landing
