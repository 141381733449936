import React from 'react'
import RestaurantList from '../components/RestaurantList';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import {ToastContainer} from 'react-toastify';

const Home = () => {
    return (
      <div>
        <NavBar />
        <Hero />
        <RestaurantList/>
        <ToastContainer />
      </div>
    );
  };
  
  export default Home;