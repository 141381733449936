import React, { useState, useEffect, useContext } from 'react';
import RestaurantFinder from '../APIs/RestaurantFinder';
import { ItemsContext } from '../context/ItemsContext';
import { useParams } from 'react-router-dom';
import ItemAdd from './ItemAdd';
import Pagination from './Pagination';

const ItemList = () => {

  const {items, setItems} = useContext(ItemsContext)
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() =>{ 
    const FetchData = async () => {
      try{
        const response = await RestaurantFinder.get(`/api/restaurants/${id}`);
        setItems(response.data.data.items);
        console.log(response);
      }catch(err){
        console.log(err);
      }
  };
  FetchData();
  },[]);

  //Indexing for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = items.slice(indexOfFirstPost, indexOfLastPost);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="wrapper">
        <div className="row">
          <h2 className="text-warning text-center">
          Restaurant Items
          </h2>
          <ItemAdd />   
          {currentPosts?.map((item) => {
              return (
              <div className="col-sm-12 col-md-6 col-lg-4 mb-4 text-center" style={{maxHeight:"360px"}} key={item?.id}>
                  <img className="p-1 img-thumbnail" src={item?.imageUrl} style={{maxHeight:"200px"}} alt="Popular Post"/>
                  <div className="p-1 media-body ">
                    <h4 className='p-1 text-warning'>{item?.dish}</h4>
                    <p className='text-light'>{item?.review}</p>
                    <p className='text-light'>
                      <i className="fas fa-star mr-2 py-1"></i> {item?.rating} / 10
                    </p>
                  </div>
              </div> 
              )
          })}  
	</div>
      <Pagination postsPerPage={postsPerPage} totalPosts={items.length} currentPage={currentPage} paginate={paginate}/>
  </section>

  )
}

export default ItemList;
