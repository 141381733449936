import React from 'react'
import RestaurantAdd from './RestaurantAdd'
import MeAndRuthie from '../Images/spfBackground.png'

const Hero = () => {
  return (
    <div style={{height: '60vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url('${MeAndRuthie}')`, 
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'}}>
        <div style={{
          maxWidth: '360px',
          justifyContent: 'center',
          alignItems: 'center'
          }}>
          <h1 className="text-warning bg-dark bg-opacity-50">Welcome to 7point5</h1>
          <h5 className="text-light bg-dark bg-opacity-50">A site dedicated to not only reviewing the restaurants we visit, but also saving the times that we've shared together</h5>
          <RestaurantAdd />
        </div>
    </div>
  )
}

export default Hero
