import React, { useState, useEffect } from 'react';
import RestaurantFinder from '../APIs/RestaurantFinder';
import { useParams } from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RestaurantEdit = (props) => {
    const { id } = useParams();
    const [success, setSuccess] = useState({});
    const [errors, setErrors] = useState({});
    const [name, setName] = useState("");
    const [area, setArea] = useState("");
    const [address, setAddress] = useState("");
    const [cuisineType, setCuisineType] = useState("");
    const [description, setDescription] = useState("");
    const [vibeRate, setVibeRate] = useState("");
    const [serviceRate, setServiceRate] = useState("");
    const [priceRange, setPriceRange] = useState("");
    const [photo, setPhoto] = useState([]);

    const fetchData = async() => {
      const response = await RestaurantFinder.get(`/api/restaurants/${id}`);
      console.log(response.data.data);
      setName(response.data.data.restaurant.restaurant_name);
      setArea(response.data.data.restaurant.area);
      setAddress(response.data.data.restaurant.restaurant_address);
      setCuisineType(response.data.data.restaurant.cuisine_type);
      setDescription(response.data.data.restaurant.restaurant_description);
      setVibeRate(response.data.data.restaurant.vibe_rate);
      setServiceRate(response.data.data.restaurant.service_rate);
      setPriceRange(response.data.data.restaurant.price_range);
    };

     useEffect(() => {
      fetchData();
    }, [])

    const photoSelected = (e) => {
      const photo = e.target.files[0]
      setPhoto(photo)
     }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        const validationErrors = {}
        const validationSuccess = {}
        //Error catching - This should be a function but it's going to be like this for now
        if(name.trim() === "") {
          validationErrors.name = "Restaurant name is required"
        }else if (name.length > 30) {
          validationErrors.name = "Name must be less than 50 Characters"
        }
        if(area.trim() === "") {
          validationErrors.area = "Please select an area"
        }
        if(address.trim() === "") {
          validationErrors.address = "Address is required"
        }else if (address.length > 30) {
          validationErrors.address = "Address must be less than 30 Characters"
        }
        if(cuisineType.trim() === "") {
          validationErrors.cuisineType = "Please select a cuisine type"
        }
        if(description.length > 500) {
          validationErrors.description = "Description must be less than 500chars"
        }
        if(vibeRate.trim() === ""){
          validationErrors.vibeRate = "Please rate the vibe"
        }else if(vibeRate <= 0 || vibeRate > 9.9 ){
          validationErrors.vibeRate = "Value must be from 0.1-9.9"
        }
        if(serviceRate.trim() === ""){
          validationErrors.serviceRate = "Please rate the service"
        }else if(serviceRate <= 0 || serviceRate > 9.9 ){
          validationErrors.serviceRate = "Value must be from 0.1-9.9"
        }
        if(priceRange === "") {
          validationErrors.priceRange = "Please select a price range"
        }
        if(photo.length === 0) {
          validationErrors.photo = "Please add a photo so we can keep the site pretty :)"
        }
        setErrors(validationErrors)
        setSuccess(validationSuccess)
        if(Object.keys(validationErrors).length === 0) {
          const spinner = toast.loading("Making Changes");
          try{
          const formData = new FormData();
          formData.append("restaurant_name", name)
          formData.append("area", area)
          formData.append("restaurant_address", address)
          formData.append("cuisine_type", cuisineType)
          formData.append("restaurant_description", description)
          formData.append("vibe_rate", vibeRate)
          formData.append("service_rate", serviceRate)
          formData.append("price_range", priceRange)
          formData.append("photo", photo)
          const updatedRestaurant = await RestaurantFinder.put(`/api/restaurants/${id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
          console.log(updatedRestaurant);
          toast.update(spinner, {render: "Restaurant Updated, changes will show next time the page loads!",
            type: "success",
            isLoading: false,
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "dark",
          })
        }catch(err){
          toast.update(spinner, {render: "Something went wrong :(",
          type: "error",
          isLoading: false,
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
      })
        }
      }else{
        toast.error("Update failed, try again and review your errors!",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        })
      }
    };
    
    return (
        <div className='p-1 mx-5'>
          <button type="button" className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#restaurantEditModal">
          Edit Details
          </button>
        {/*Start of modal*/} 
          <div className="modal fade" id="restaurantEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-dark" id="staticBackdropLabel">Edit Restaurant Details</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
        {/*Start of modal body*/}
                <div className="modal-body">
        {/*Form Body start*/}
                  <form action='upload' method='post' onSubmit={handleSubmit} encType='multipart/form-data'>
                    <div className="row g-3">
                      <div className="col-md-6 text-dark">
                        <label htmlFor="restaurantName">Restaurant Name</label>
                        <input value={name} type="text" className="form-control" id="restaurantName" autoComplete='off' disabled readOnly></input>
                        {errors.name && <span style={{color:'red'}}>{errors.name}</span>}
                      </div>
                      <div className="col-md-6 text-dark">
                        <label htmlFor="restaurantArea">Restaurant Area</label>
                        <select value={area} onChange={e => setArea(e.target.value)} id="restaurantArea" className="form-select">
                          <option value>Select One</option>
                          <option>Mount Pleasant</option>
                          <option>China Town</option>
                          <option>East Van</option>
                          <option>Yaletown</option>
                          <option>Olympic Village</option>
                          <option>Gastown</option>
                          <option>Downtown</option>
                          <option>North Vancouver</option>
                          <option>Burnaby</option>
                          <option>Richmond</option>
                          <option>Victoria</option>
                          <option>Other (Specify)</option>
                        </select>
                        {errors.area && <span style={{color:'red'}}>{errors.area}</span>}
                      </div>
                      <div id="restaurantNameHelpBlock" className="form-text">
                        Restaurant Name Can't Edited
                      </div>
                      <div className="col-md-12 text-dark">
                        <label htmlFor="restaurantAddress">Restaurant Address</label>
                        <input value={address} onChange={e => setAddress(e.target.value)} type="text" className="form-control" id="restaurantAddress" autoComplete='off'></input>
                        {errors.address && <span style={{color:'red'}}>{errors.address}</span>}
                      </div>
                      <div id="restaurantNameHelpBlock" className="form-text ">
                        Ex. 01-1234 Apple Street, City
                      </div>
                      <div className="col-md-6 text-dark">
                        <label htmlFor="cuisineType">Cuisine Type</label>
                        <select value={cuisineType} onChange={e => setCuisineType(e.target.value)} id="cuisineType" className="form-select">
                          <option value>Select One</option>
                          <option>Asian</option>
                          <option>Indian</option>
                          <option>Pub Fare</option>
                          <option>Chef's Menu</option>
                          <option>Italian</option>
                          <option>Mexican</option>
                          <option>Japanese</option>
                          <option>Plant Based</option>
                          <option>Fast Food</option>
                          <option>Mediteranean</option>
                          <option>Cafe</option>
                          <option>Other (Specify)</option>
                        </select>
                        {errors.cuisineType && <span style={{color:'red'}}>{errors.cuisineType}</span>}
                      </div>
                      <div className="col-md-12 text-dark">
                        <label htmlFor="restaurantDescription">Restaurant Description</label>
                        <input value={description} onChange={e => setDescription(e.target.value)} type="text" className="form-control" id="restaurantDescription" autoComplete='off'></input>
                        {errors.description && <span style={{color:'red'}}>{errors.description}</span>}
                      </div>
                      <div id="restaurantNameHelpBlock" className="form-text ">
                        Describe your time at the restaurant, you can describe what you ate later!
                      </div>
                      <div className="col-md-3 text-dark">
                        <label htmlFor="vibeRate">Vibe Rating</label>
                        <input value={vibeRate} onChange={e => setVibeRate(e.target.value)} type="number" className="form-control" id="vibeRate" min="0" max="10" step="0.1"></input>
                        {errors.vibeRate && <span style={{color:'red'}}>{errors.vibeRate}</span>}
                      </div>
                      <div className="col-md-3 text-dark">
                        <label htmlFor="serviceRate">Service Rating</label>
                        <input value={serviceRate} onChange={e => setServiceRate(e.target.value)} type="number" className="form-control" id="serviceRate" min="0" max="10" step="0.1"></input>
                        {errors.serviceRate && <span style={{color:'red'}}>{errors.serviceRate}</span>}
                      </div>
                      <div className="col-md-6 text-dark">
                        <label htmlFor="priceRange">Price Range</label>
                        <select value={priceRange} onChange={e => setPriceRange(e.target.value)} id="priceRange" className="form-select">
                          <option value>Select One</option>
                          <option value="4">$$$$ (Over $40)</option>
                          <option value="3">$$$  ($25-$40)</option>
                          <option value="2">$$   ($12-$25)</option>
                          <option value="1">$    (Under $12)</option>
                        </select>
                        {errors.priceRange && <span style={{color:'red'}}>{errors.priceRange}</span>}
                      </div>
                      <div className="col-md-12 text-dark">
                        <label htmlFor="photoUpload">Upload a Photo</label>
                        <input defaultValue={photo} onChange={photoSelected} type="file" className="form-control" id="photoUpload"></input>
                        {errors.photo && <span style={{color:'red'}}>{errors.photo}</span>}
                      </div>
                    </div>
                    {success.message && <span style={{color:'green'}}>{success.message}</span>}
                    <div className="modal-footer">
                      <button className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-warning" data-bs-dismiss="modal">Confirm Changes</button>
                    </div>
                  </form>
        {/*Form Body End*/}
                </div>
        {/*End Modal Body*/}
              </div>
            </div>
          </div>
        </div>
  )
}

export default RestaurantEdit;
