import React from 'react'
import {Chart, RadialLinearScale, ArcElement, Title, Tooltip} from 'chart.js'
import {PolarArea} from 'react-chartjs-2'

const PolarAreaGraph = ({Vibe, Service, Food, Price}) => {
  Chart.register(RadialLinearScale, ArcElement, Title, Tooltip)
  const values = [Vibe, Service, Food, Price*2.5];
  let data = {
    labels: [
      'Vibe',
      'Service',
      'Average Food Rating',
      'Price Range'
    ],
    datasets: [{
      label: 'Restaurant Metrics',
      data: values,
      backgroundColor: [
        'rgba(211, 84, 0, 0.8)',
        'rgba(249, 105, 14, 0.8)',
        'rgba(243, 156, 18, 0.8)',
        'rgba(242, 120, 75, 0.8)'
      ]
    }]
  }
  let options ={
    responsive: true,
    hoverBorderWidth:2,
    borderWidth:0,
    plugins:{
      legend:{
        display:false
      }
    },
    scales:{
      r:{
        suggestedMin: 0,
        suggestedMax: 10,
        grid:{
          circular:true,
          color:"rgba(255,255,255, 0.5"
        },
        angleLines:{
          display:true,
          color:"rgba(255,255,255, 0.5)",
          lineWidth:1
        },
        pointLabels:{
          display:true,
          centerPointLabels:true,
          color:"rgba(255,255,255,1)"
        },
        ticks:{
          display:false,
          stepSize:2,
          color:"rgba(255,255,255,1)",
          z:1,
          backdropColor:"rgba(0,0,0,1)"
        }
      }
    }
  }
  let stylePolarArea={
    maxWidth:"360px",
    height:"auto"
  }
  return (
    <div style={stylePolarArea}>
      <PolarArea data={data} options={options} />
    </div>
  )
}

export default PolarAreaGraph
