import React from 'react'
import GuestList from '../components/GuestList'
import GuestBook from '../components/GuestBook'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const Guest = () => {

  const navigate = useNavigate();  

  const handleButton = () => {
    navigate('/');
  }
  
  return (
    <div>
      <div className='text-center mx-2'>
        <p className='text-light'>Welcome to the <strong className='text-warning'>7point5</strong> Guest Page.</p>
        <button className='btn btn-dark' onClick={handleButton}>Use this button to return to the homepage</button>
        <p className='text-light my-4' >or feel free to leave your name to let us know you've visited the site, and feel free to peruse an overview of what goes on in <strong className='text-warning'>7point5.club</strong></p>
      </div>
      <GuestBook />
      <GuestList />
      <ToastContainer />
    </div>
  )
}

export default Guest
