import React, { useEffect, useRef, useState} from 'react'
import RestaurantFinder from '../APIs/RestaurantFinder';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const {setAuth} = useAuth();
    const userRef = useRef();
    const navigate = useNavigate();

    const [pwd, setPwd] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, []);
    const toGuest = (e) => {
        e.preventDefault();
        navigate('/guest');
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const response = await RestaurantFinder.post(`api/auth`, {
                pwd: pwd
            })
            console.log(response)
            const accessToken = response.data.data.AccessToken;
            const user = "valid";
            setAuth({user, pwd, accessToken});
            setPwd('');
            navigate('/restaurants', {replace: true});
        }catch(err){
            console.log(err)
            try{
                toast.warn('Incorrect Code', {
                    position: "top-right",
                    autoClose: 3000,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    theme: "dark",
                });
            }catch(err){
                console.log(err)
            }
        }
        
    }

    return (
        <div className='text-center'>
            <div className='css-typing text-center'>
                <p className='text-light p-1'> Welcome to <strong className='text-warning'>7point5</strong></p>
                <p className='text-light p-1'>Enter Your Access Code</p>
            </div>
            <form onSubmit={handleSubmit}>
                <input className="d-inline-flex focus-ring focus-ring-warning text-decoration-none border rounded-2"
                    type="password"
                    id="password"
                    ref={userRef}
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                />
                <button className='btn btn-warning mx-2'>Verify</button>
            </form>
        <ToastContainer />
        <button className='btn btn-dark my-3' onClick={toGuest}>
            Visit the Guest Page
        </button>
        </div>
    )
}

export default Login
