// src/components/ReviewList.js
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RestaurantsContext } from '../context/RestaurantsContext';
import RestaurantFinder from '../APIs/RestaurantFinder';
import RestaurantEdit from './RestaurantEdit'
import ItemList from './ItemList';
import ScrollToTop from "react-scroll-to-top";
import PolarAreaGraph from './PolarAreaGraph';
import RadarChartGraph from './RadarChartGraph';

const RestaurantReview = (props) => {
  const {id} = useParams();
  const {selectedRestaurant, setSelectedRestaurant} = useContext(RestaurantsContext)
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await RestaurantFinder.get(`/api/restaurants/${id}`);
        console.log(response);
        setSelectedRestaurant(response.data.data);
      }catch(err){
        console.log(err);
      }
    }
    fetchData();
  }, [])
  const showGraph = () => {
    if(showResults){
      setShowResults(false)
    }else{
      setShowResults(true)
    }
  }
  return (
	  <div className="container">
		<article className="postcard dark">
				<img className="postcard__img" src={selectedRestaurant.restaurant?.imageUrl} alt="The outside of the restaurant" />
			<div className="postcard__text">
				<h1 className="postcard__title text-warning mx-5">{selectedRestaurant.restaurant?.restaurant_name}</h1>
				<div className="postcard__subtitle small mx-5">
						<i className="fas fa-map-pin p-1"></i> {selectedRestaurant.restaurant?.area} - {selectedRestaurant.restaurant?.restaurant_address}
				</div>
        <RestaurantEdit />
        <div className="postcard__bar"></div>
				<div className="postcard__preview-txt mx-4">{selectedRestaurant.restaurant?.restaurant_description}</div>
          <ul className='list-unstyled mx-4 my-2'>
            <li><i className="fas fa-bolt mr-2 py-1"></i> Vibe: {selectedRestaurant.restaurant?.vibe_rate}</li>
            <li><i className="fa-solid fa-hand-holding-heart mr-2 py-1"></i> Service: {selectedRestaurant.restaurant?.service_rate}</li>
            <li><i className="fas fa-tag"></i> Price Range: {"$".repeat(selectedRestaurant.restaurant?.price_range)}</li>
          </ul>
        <button type="button" className='btn btn-dark my-2' onClick={showGraph} style={{maxWidth:"360px"}}>Toggle Metrics</button>
        {showResults ? <div>
          <PolarAreaGraph 
            Vibe={selectedRestaurant.restaurant?.vibe_rate}
            Service={selectedRestaurant.restaurant?.service_rate}
            Food={selectedRestaurant.food_rate?.avg}
            Price={selectedRestaurant.restaurant?.price_range}/>
          </div>: null}
			</div>
		</article>
    <ItemList />
  <ScrollToTop smooth />
    </div>
  );
}

export default RestaurantReview;
