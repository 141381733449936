import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const handleNav = () => {
      navigate(`/`)
  }
  return (
    <div className='text-center text-warning'>
      <h2>404 - Not Found</h2>
      <p className='text-white'>The page you are looking for does not exist.</p>
      <button className="btn btn-dark" onClick={() => handleNav()}>Back to Safety</button>
    </div>
  );
};

export default NotFound;
