// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Routes/Home';
import NotFound from './Routes/NotFound';
import About from './Routes/About';
import Guest from './Routes/Guest';
import { RestaurantsContextProvider } from './context/RestaurantsContext';
import { ItemsContextProvider } from './context/ItemsContext';
import { AuthContextProvider} from './context/AuthContext';
import { SignaturesContextProvider } from './context/SignaturesContext';
import RestaurantDetails from './Routes/RestaurantDetails';
import Landing from './Routes/Landing';
import ScrollToTop from './components/ScrollToTop';
import RequireAuth from './components/RequireAuth';


const App = () => {
  return (
    <SignaturesContextProvider >
    <AuthContextProvider>
    <ItemsContextProvider>
    <RestaurantsContextProvider>
        <BrowserRouter>
          <ScrollToTop>
          <Routes>
            <Route exact path="/guest" element={<Guest />} />
            <Route exact path="/" element={<Landing/>}/>
            <Route exact path="*" element={<NotFound/>}/>
            <Route element={<RequireAuth />}>
              <Route exact path="/restaurants" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="restaurants/:id" element={<RestaurantDetails />} />
              <Route exact path="/restaurants/:id/more" element={<NotFound />} />
            </Route>
          </Routes>
          </ScrollToTop>
        </BrowserRouter>
    </RestaurantsContextProvider>
    </ItemsContextProvider>
    </AuthContextProvider>
    </SignaturesContextProvider>
  );
}

export default App;

