import React from 'react'
import { Chart, Filler, LineElement, PointElement, Tooltip, Legend, RadialLinearScale } from 'chart.js'
import {Radar} from 'react-chartjs-2'

const RadarChartGraph = ({Vibe, Service, Food, Price}) => {
  Chart.register(
    LineElement,
    PointElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler
  )
  const data = {
    labels:["Vibe", "Service", "Avg Food Rating", "Price Range"],
    datasets: [{
      label:"Average Ratings",
      data: [5, 5, 5, 5],
      backgroundColor:'rgba(236, 100, 75, 0.3)',
      borderColor: 'rgb(150, 40, 27)',
      pointBackgroundColor: 'rgba(150, 40, 27)',
      pointBorderColor: 'rgba(0,0,0,)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(0, 0, 0)'
    },{
      label:"Restaurant Ratings",
      data: [Vibe, Service, Food, Price*2.5],
      fill: true,
      backgroundColor:'rgba(243, 156, 18, 0.8)',
      borderColor: 'rgba(243, 156, 18)',
      pointBackgroundColor: 'rgb(243, 156, 18)',
      pointBorderColor: 'rgba(0, 0, 0)',
      pointHoverBackgroundColor: 'rgba(243, 156, 18)',
      pointHoverBorderColor: 'rgb(0,0,0)'
    }]
  }
  const options = {
    scales:{
      r:{
        suggestedMin: 0,
        suggestedMax: 10,
        backgroundColor:'black',
        angleLines:{
          color:"rgba(255,255,255, 0.5"
        },
        grid:{
          color:"rgba(255,255,255, 0.5"
        },
        pointLabels:{
          color:'white'
        },
        ticks:{
          display:false,
          stepSize:2,
          color:"rgba(255,255,255,1)",
          z:1,
          backdropColor:"rgba(0,0,0,1)"
        },
      }
    }
  }
  let styleRadarChart={
    maxWidth:"360px",
    height:"auto"
  }
  return (
    <div style={styleRadarChart}>
      <Radar data={data} options={options} />
    </div>
  )
}

export default RadarChartGraph
