import React, { useState, useEffect, useContext } from 'react';
import RestaurantFinder from '../APIs/RestaurantFinder';
import { SignaturesContext } from '../context/SignaturesContext';
import GuestBookAdd from './GuestBookAdd';
import Pagination from './Pagination';
import {v4 as uuidv4} from 'uuid';

const GuestBook = () => {

  const {signatures, setSignatures} = useContext(SignaturesContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  
  //Load Data
  useEffect(() =>{ 
    const FetchData = async () => {
      try{
        const response = await RestaurantFinder.get(`/api/guestbook`);
        setSignatures(response.data.data.signatures);
      }catch(err){
        console.log(err);
      }
  };
  FetchData();
  },[]);
  //Indexing for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = signatures.slice(indexOfFirstPost, indexOfLastPost);
  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <section className='wrapper'>
      <div className='row text-center'> 
        {currentPosts?.map((signature) => {
            return (
            <div className='text-light border rounded border-dark my-2' style={{maxWidth:"300px"}} key={uuidv4()}>
                <div className='text-center'>
                  <p className='text-warning'>{signature?.guest}</p>  
                  <p><i className='fas fa-calendar-days' /> {signature?.guest_date}</p>
                </div>
            </div> 
            )
        })}  
      <GuestBookAdd />
      <Pagination postsPerPage={postsPerPage} totalPosts={signatures.length} currentPage={currentPage} paginate={paginate}/> 
      </div>
      <p className='text-light text-center'>Thanks to all <strong className='text-warning'>{signatures.length}</strong> signees and counting!</p>

  </section>

  )
}

export default GuestBook;
